import client from '../thinMintClient'
import { resolveFilePayload } from '../resolvers'
import { resolveEntity, resolveEntityPayload } from './resolver'
import { SnakeToCamel } from '../types'

const baseEndpoint = '/entities'

export interface EntityDocument {
  id: string
  name: string
}

export interface EntityPayloadDocument {
  documentid: string
  documentTitle: string
}

export interface EntityPayload {
  id: string
  name: string
  email: string
  address1: string
  address2?: string
  city: string
  state: string
  ein?: string
  entity_type: string
  account_id: number
  phone_number: string
  postal_code: string
  has_ein: boolean
  escrow_agent_entity_id: string
}

export type Entity = SnakeToCamel<EntityPayload>

export type EntityForm = Partial<Entity>

export const getEntities = () => ({
  key: `${baseEndpoint}/`,
  request: () => client.get(`${baseEndpoint}/`),
  resolve: (res: { data: { results: EntityPayload[] } }) =>
    res.data.results.map(resolveEntity),
})

export const createEntity = () => ({
  request: (entity: EntityForm) =>
    client.post(`${baseEndpoint}/`, resolveEntityPayload(entity)),
  resolve: (res: { data: EntityPayload }) => {
    return resolveEntity(res.data)
  },
})

export const updateEntity = (entityId: string) => ({
  request: (entity: EntityForm) =>
    client.put(`${baseEndpoint}/${entityId}/`, resolveEntityPayload(entity)),
  resolve: (res: { data: EntityPayload }) => {
    return resolveEntity(res.data)
  },
})

export const getEntityDocuments = (entityId: string) => ({
  key: `${baseEndpoint}/${entityId}/list_documents/`,
  request: () => client.get(`${baseEndpoint}/${entityId}/list_documents/`),
  resolve: (res: { data: EntityPayloadDocument[] }) => {
    return res.data.map(({ documentid, documentTitle }) => ({
      id: documentid,
      name: documentTitle,
    }))
  },
})

export const uploadEntityDocument = (entityId: string) => ({
  request: (file: File) =>
    client.post(
      `${baseEndpoint}/${entityId}/upload_document/`,
      resolveFilePayload(file)
    ),
})

export const removeEntityDocument = (entityId: string) => ({
  request: (id: string) =>
    client
      .delete(`${baseEndpoint}/${entityId}/delete_document/${id}/`)
      .then((res) => {
        if (res.data !== 'Document deleted successfully') {
          throw new Error('Unable to delete document. Please try again.')
        }
        return res.data
      }),
})

export default {
  baseEndpoint,
  getEntities,
  createEntity,
  updateEntity,
  getEntityDocuments,
  uploadEntityDocument,
  removeEntityDocument,
}
