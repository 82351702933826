import { resolvePhoneNumber } from '../resolvers'
import { Entity, EntityForm, EntityPayload } from './entity'
import { casingUtil } from '@/utils'

export const resolveEntity = ({
  phone_number,
  ...rest
}: EntityPayload): Entity => ({
  ...(casingUtil.snakeToCamel(rest) as Entity),
  phoneNumber: resolvePhoneNumber(phone_number),
})

export const resolveEntityPayload = (entityForm: EntityForm): EntityPayload =>
  casingUtil.camelToSnake(entityForm) as EntityPayload
